// src/pages/Home/Home.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';

import spacedsaImage from '../../assets/spacedsa/cover_card.png'; // Replace with actual image
// import clickcvImage from '../../assets/clickcv/resume-example.png'; // Replace with actual image

function Home() {
  return (
    <div className={styles.home}>
      <header className={styles.hero}>
        <p>Software that elevates your potential</p>
      </header>
      <section className={styles.apps}>
        {/* SpaceDSA App Card */}
        <div className={styles.appCard}>
          {/* Optional: Add an image for SpaceDSA */}
          <img src={spacedsaImage} alt="SpaceDSA Screenshot" className={styles.appImage} />
          <p>
            Study data structures and algorithms effectively using spaced repetition.
            Track your LeetCode problem-solving time, and automatically schedule problem
            reviews to reinforce your learning. Only review what you are soon to forget,
            and even focus your review sessions on problems by difficulty and type.
          </p>
          <Link to="/spacedsa" className={styles.button}>
            Learn More
          </Link>
        </div>

        {/* ClickCV App Card */}
        {/* <div className={styles.appCard}> */}
          {/* Optional: Add an image for ClickCV */}
          {/* <img src={clickcvImage} alt="ClickCV Screenshot" className={styles.appImage} /> */}
          {/* <h2>ClickCV</h2>
          <p>
            Resumes Tailored for Every Job App. ClickCV takes your given experience to instantly
            create tailored, drag-and-drop resumes that best match any job you apply to.
            Built for Chrome and compatible with common job boards.
          </p>
          <Link to="/clickcv" className={styles.button}>
            Learn More
          </Link>
        </div> */}
      </section>
    </div>
  );
}

export default Home;

