// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.js';
import Footer from './components/Footer/Footer.js';
import Home from './pages/Home/Home.js';
import SpaceDSA from './pages/SpaceDSA/SpaceDSA.js';
import ClickCV from './pages/ClickCV/ClickCV.js';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/clickcv" element={<ClickCV />} />
            <Route path="/spacedsa" element={<SpaceDSA />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

