import React from 'react';
import styles from './SpaceDSA.module.css';
import logo from '../../assets/spacedsa/logo.png'; // Replace with your logo
import screenshot1 from '../../assets/spacedsa/1.png'; // Replace with actual screenshots
import screenshot2 from '../../assets/spacedsa/2.png';

function SpaceDSA() {
  return (
    <div className={styles.container}>
      {/* Left Sidebar */}
      <aside className={styles.sidebar}>
        <div className={styles.header}>
          <img src={logo} alt="SpaceDSA Logo" className={styles.logo} />
          <h1>SpaceDSA: Efficient Leetcode Prep</h1>
          <p>Study data structures and algorithms effectively using spaced repetition.</p>
        </div>
        <hr></hr>
        <p>
          Many people struggle with DSA because they don’t consistently revisit and practice problems they’ve already learned. Without regular review, it’s easy to forget key concepts.
        </p>

        <p>
          SpaceDSA sets you on a spaced repetition-based study routine that keeps you practicing and reinforcing past topics. SpaceDSA isn’t a curated list—use any problem set you choose and check your scheduled reviews to reinforce what you have covered. Whether you're preparing for technical interviews or honing your skills, SpaceDSA ensures you’re continuously re-engaging with concepts to master data structures and algorithms more efficiently than ever before.
        </p>
        <br></br>
        <a href="https://chromewebstore.google.com/detail/spacedsa/phdohlijhchimahmdicclcimgdlcegdd?hl=en-US" className={styles.downloadButton}>
          Download
        </a>
      </aside>

      {/* Right Content */}
      <section className={styles.editorSection}>
      <section className={styles.features}>
          <h2>Enhance Your Retention</h2>
          <p>
            Track your LeetCode problem-solving time, and automatically schedule problem reviews to
            reinforce your learning. Only review what you are soon to forget, and even focus your
            review sessions on problems by difficulty and type.
          </p>
        </section>
        <div className={styles.screenshots}>
          <img src={screenshot1} alt="SpaceDSA Screenshot 1" className={styles.screenshot} />
          <img src={screenshot2} alt="SpaceDSA Screenshot 2" className={styles.screenshot} />
        </div>
        <div className={styles.disclaimer}>
          <p>Cuitt LLC</p>
          <p>Disclaimer: Leetcode is not affiliated with nor endorses SpaceDSA.</p>
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f57cfa10-fc42-4b3d-b335-ec8b09657249" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
      </section>
    </div>
  );
}

export default SpaceDSA;
