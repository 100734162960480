// src/pages/ClickCV/ClickCV.jsx
import React from 'react';
import styles from './ClickCV.module.css';
import logo from '../../assets/clickcv/logo.png'; // Replace with your logo
import feature1 from '../../assets/clickcv/feature1.png'; // Replace with actual image paths
import resumeExample from '../../assets/clickcv/example-resume.png';

function ClickCV() {
  return (
    <div className={styles.container}>
      {/* Left Sidebar */}
      <aside className={styles.sidebar}>
        <div className={styles.header}>
          <img src={logo} alt="ClickCV Logo" className={styles.logo} />
          <h1>ClickCV: Resumes Tailored for Every Job App</h1>
          <p>
          Built for Chrome and compatible with common job boards.
          </p>
        </div>
        <p>
          When you're qualified and applying to multiple job titles, crafting fine-tuned resumes can take up valuable time that could be spent applying to more jobs. ClickCV takes your given experience to instantly create tailored, drag-and-drop resumes that best match any job you apply to.
        </p>
        {/* Placeholder images or icons */}
        <div className={styles.imageGallery}>
          {/* <img src={feature1} alt="Feature 1" className={styles.placeholder} />
          <img src={feature2} alt="Feature 2" className={styles.placeholder} />
          <img src={feature1} alt="Feature 3" className={styles.placeholder} />
          <img src={feature2} alt="Feature 4" className={styles.placeholder} />
          <img src={feature1} alt="Feature 5" className={styles.placeholder} /> */}
        </div>
        <br></br>
        <a href="https://chromewebstore.google.com/detail/clickcv/blndoopcfepmdfbbajpbldigolihligc?authuser=0&hl=en" className={styles.downloadButton}>
          Download
        </a>
      </aside>

      {/* Right Content */}
      <section className={styles.editorSection}>
        <h2>Show Recruiters You're the Perfect Fit</h2>
        <p>
          ClickCV compiles your resume content based on the job requirements found in each listing you apply to.
        </p>
        <p>
          You are still the writer! Enter all your qualifications and let ClickCV do the rest. Your sections, bullet points, and other info are added, removed, shifted and tweaked until your most relevant points are front and center in an easy to submit PDF.
        </p>

        <img src={feature1} alt="Feature 1" className={styles.featureImage} />

        <p>
          Whether you quickly apply to many jobs or already carefully tailor each resume, ClickCV lets you spend more time applying and less time worrying about the fine details.
        </p>

        <h3>Example Resume Generated with ClickCV</h3>
        <img src={resumeExample} alt="Example Resume" className={styles.resumeExample} />

        <h2>Somebody Already Works There, You Can Too</h2>
        <p>
          Enable LinkedIn scraping to factor in the profile of someone currently holding the same position at the same company you're applying to. If any of their profile content aligns with your experience, these points will be emphasized in your resume, and increase your chances of getting hired.
        </p>

        <h2>Every Resume is an Opportunity to Achieve Your Next Career Leap</h2>
        <p>
          Get the edge on the competition.
        </p>

        {/* Disclaimer */}
        <div className={styles.disclaimer}>
          <p>Cuitt LLC</p>
          <p>Disclaimer: None of the job boards depicted are affiliated with or endorse ClickCV.           We do not collect or share the personal data from which your resumes are based upon.</p>
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f57cfa10-fc42-4b3d-b335-ec8b09657249" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
      </section>
    </div>
  );
}

export default ClickCV;
