// src/components/Navbar/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../../assets/cpi/logo.png'; // Replace with your logo

function Navbar() {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <img src={logo} alt="Castle Point Innovations Logo" className={styles.logo} />
        <Link to="/">{"<Castle Point Innovations>"}</Link>
      </div>
      <ul className={styles.navLinks}>
        <li>
          <Link to="/spacedsa">SpaceDSA</Link>
        </li>
        <li>
          <Link to="/clickcv">ClickCV</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
